<template>
	<div class="contact-form-wrap" id="consultation">
		<p class="sub-title">ЗАЦІКАВИЛО?</p>
		<h2 class="title">Давайте поспілкуємось!</h2>
		<div class="contact-form">
			<form id="app-form" @submit.prevent="checkForm">
				<input type="hidden" name="page" id="page" v-model="pageTitle">
				<div class="input-block">
					<input type="text" name="name" id="name" v-model="name">
					<label for="name"><span class="paceholder" :class="[{ 'not-empty paceholder': name.length != 0 }, 'paceholder']">Ваше імя</span></label>
				</div>
				<div class="input-block">
					<input type="text" name="contact" id="contact" v-model="contact">
					<label for="contact"><span :class="[{ 'not-empty paceholder': contact.length != 0 }, 'paceholder']" >Телефон або e-mail</span></label>
				</div>
				<div class="errors" v-if="errors.length">
						<ul>
							<li v-for="error in errors" :key="error">{{ error }}</li>
						</ul>
				</div>

				<button type="submit" class="btn">ЧЕКАЮ КОНСУЛЬТАЦІЮ</button>   

			</form>
		</div>
	</div>
</template>

<style scoped lang="scss">
	@import "../assets/scss/components/contact-form.scss";
</style>

<script>
import axios from 'axios';
export default {
	data(){
		return {
			errors:[],
			name: '',
			nameEror: false,
			contact: '',
			contactError: false,
			pageTitle: this.$route.meta.title,
		}
	},
	methods:{
		checkForm: function (e) {
			this.errors = [];

			if (!this.name) {
				this.errors.push('Вкажіть Ваше і\'мя.');
				this.nameEror = true;
			}
			if (!this.contact) {
				this.errors.push('Заповніть ваші контакти.');
			} else if (this.contact.match(/^[0-9]+$/) == null) {
				if(!this.validEmail(this.contact)){
					this.errors.push('Вкажіть коректну адресу електроної пошти');
					this.contactError = true;
				}
			} else if(this.contact.match(/^[0-9]+$/) != null){
				if(this.contact.length <10){
					this.errors.push('Вкажіть коректний номер телефону');
					this.contactError = true;
				}
			}

			if (!this.errors.length) {
				this.contactError = false;
				this.nameEror = false;
			return this.sendMail();

			}
			e.preventDefault()
			
		},
		validEmail: function (contact) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(contact);
		},
		sendMail() {
			const mg = {
				apiKey: process.env.VUE_APP_MAILGUN_KEY, 
				domain: process.env.VUE_APP_MAILGUN_DOMAIN,
				emailFrom: process.env.VUE_APP_MAILGUN_FROM
			}

			const Url = `https://api.mailgun.net/v3/${mg.domain}/messages` 
			axios({
				method: 'post',
					url: Url,
					auth: {
				username: 'api',
				password: mg.apiKey
				},
				params: {
				from: `Lemberg Flowers<${mg.emailFrom}>`,
				to: 'lembergflowers@gmail.com',
				cc: 'kravets.lviv@gmail.com',
				subject: 'Запит з сайту Lemberg Flowers',
				html: `<p><b>І'мя:</b> ${this.name}</p> 
				<p><b>Контакти:</b> ${this.contact}</p>
				<p><b>Сторінка:</b> ${this.pageTitle}</p>	`
				}
				}).then(
				response => {
					if(response)
					this.$router.push({ name: 'thank-you'});
					},
				reject => {
					this.errors.push(reject);
					}
				)
		}
	}
}
</script>