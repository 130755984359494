<template>
	<div class="content">
		<section class="head-section">
			<div class="container cols-2">
				<div class="content">
					<h1 class="title"
					data-aos="fade-right"
					data-aos-duration="1000">Екскурсії для школярів</h1>
					<p
					data-aos="fade-right"
					data-aos-duration="1000"
					data-aos-delay="150">Організовуємо екскурсії для школярів. Показуємо дітям наш садовий центр та пояснюємо основні моменти та складнощі вирощування такої великої кількості рослин. Для кожної групи проводимо майстер-клас з посадки кімнатної квітки в горщик, яку вони потім забирають додому.</p>
				</div>
				<div class="images-col">

					<picture class="full-image" 
					data-aos="fade-left"
					data-aos-duration="1000"
					data-aos-delay="300">
						<source srcset="../assets/images/kids-1.webp" type="image/webp">
						<img src="../assets/images/kids-1.jpg" alt="kids excursion Flowers" :ref="setImagemRef">
					</picture>
					<picture class="hover-image"
					data-aos="fade-left"
					data-aos-duration="1000"
					>
						<source srcset="../assets/images/kids-2.webp" type="image/webp">
						<img src="../assets/images/kids-2.jpg"  alt="kids excursion Flowers" :ref="setImagemRef">
					</picture>
					<picture class="hover-image-2" 
					data-aos="fade-left"
					data-aos-duration="1000"
					data-aos-delay="400">
						<source srcset="../assets/images/kids-3.webp" type="image/webp">
						<img src="../assets/images/kids-3.jpg"  alt="kids excursion Flowers" :ref="setImagemRef">
					</picture>
				</div>
			</div>
		</section>
		<section class="section-gallery">
			<div class="container">
				<div class="gallery">
					<div v-for="(img, index) in gallery"
					:key="index" :class="'pic ' + img.size"
					@click="() => showGalleryImg(index)">
						<picture>
							<source :srcset="typeof img === 'string' ? img : img.webp" type="image/webp">
							<img :src="typeof img === 'string' ? img : img.thumbnail"  alt="kids excursion Flowers" :ref="setImagemRef">
						</picture>
					</div>
					<vue-easy-lightbox
					:visible="visibleImg"
					:imgs="gallery"
					:index="indexGallery"
					:moveDisabled = "moveDisabled"
					:scrollDisabled = "scrollDisabled"
					:swipeTolerance = "swipeTolerance"
					:escDisabled="escDisabled"
					:loop="loop"
					@hide="handleGalleryHide">
						
					</vue-easy-lightbox>
				</div>
			</div>
		</section>
		<section>
			<div class="container">
				<v-form/>
			</div>
		</section>
		<section>
			<v-contacts/>
		</section>
	</div>
</template>
<style scoped lang="scss">
	@import "../assets/scss/components/kids-excursion-page.scss";
</style>

<script>
	import VueEasyLightbox from 'vue-easy-lightbox' 
	import ContactForm from '@/components/ContactForm.vue'
	import Contacts from '@/components/Contacts.vue'

	import { ref, onMounted, onBeforeUpdate } from 'vue'


	export default {
		setup() {
			let imageRefs = ref([])
			const setImagemRef = el => {
				if (el) {
					imageRefs.value.push(el)
				}
			}
		onMounted(() => {
			imageRefs.value.forEach(function(entry) {
				entry.width = entry.clientWidth
				entry.height = entry.clientHeight
			});
		})
		onBeforeUpdate(() => {
			imageRefs.value = []

		})
			return {
				setImagemRef
			}
		},
		components: {
			VueEasyLightbox,
			'v-contacts': Contacts,
			'v-form': ContactForm
		},
		data(){
			return {
				list:[
					{
						title:'З 1-го лютого до 15 квітня',
						descr: 'Чекаємо Ваші кашпо, горщики, ящики у нашому садовому центрі'
					},
					{
						title:'Вибір рослин',
						descr: 'В нас широкий асортимент для озеленення балконів та терас'
					},
					{
						title:'Оформлення замовлення',
						descr: ''
					},
					{
						title:'Вирощування в парнику',
						descr: 'В нас створені всі умови для швидкого та правильно вирощування ваших рослин'
					},
					{
						title:'До 10 травня (по готовності)',
						descr: 'Забираєте Ваші готові замовлення'
					}
				],
				visibleImg: false,
				indexGallery: 0,
				moveDisabled:true,
				scrollDisabled : true,
				swipeTolerance : 50,
				escDisabled : true,
				loop: true,
				gallery: [
				{
					src: require('../assets/images/kids-gallery/image-1.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-1.jpg'),
					webp: require('../assets/images/kids-gallery/small-1.webp'),
					size: 'auto' 
				},
				{
					src: require('../assets/images/kids-gallery/image-2.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-2.jpg'),
					webp: require('../assets/images/kids-gallery/small-2.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-3.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-3.jpg'),
					webp: require('../assets/images/kids-gallery/small-3.webp'),
					size: 'auto' 
				},
				{
					src: require('../assets/images/kids-gallery/image-4.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-4.jpg'),
					webp: require('../assets/images/kids-gallery/small-4.webp'),
					size: 'auto' 
				},
				{
					src: require('../assets/images/kids-gallery/image-5.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-5.jpg'),
					webp: require('../assets/images/kids-gallery/small-5.webp'),
					size: 'auto' 
				},
				{
					src: require('../assets/images/kids-gallery/image-6.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-6.jpg') ,
					webp: require('../assets/images/kids-gallery/small-6.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-7.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-7.jpg') ,
					webp: require('../assets/images/kids-gallery/small-7.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-8.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-8.jpg') ,
					webp: require('../assets/images/kids-gallery/small-8.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-9.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-9.jpg') ,
					webp: require('../assets/images/kids-gallery/small-9.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-10.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-10.jpg') ,
					webp: require('../assets/images/kids-gallery/small-10.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-11.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-11.jpg') ,
					webp: require('../assets/images/kids-gallery/small-11.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-12.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-12.jpg') ,
					webp: require('../assets/images/kids-gallery/small-12.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-13.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-13.jpg') ,
					webp: require('../assets/images/kids-gallery/small-13.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-14.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-14.jpg') ,
					webp: require('../assets/images/kids-gallery/small-14.webp'),
					size: 'auto'

				},
				{
					src: require('../assets/images/kids-gallery/image-15.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-15.jpg') ,
					webp: require('../assets/images/kids-gallery/small-15.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-16.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-16.jpg') ,
					webp: require('../assets/images/kids-gallery/small-16.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-17.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-17.jpg') ,
					webp: require('../assets/images/kids-gallery/small-17.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-18.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-18.jpg') ,
					webp: require('../assets/images/kids-gallery/small-18.webp'),
					size: 'auto'
				},
				{
					src: require('../assets/images/kids-gallery/image-19.jpg'),
					thumbnail: require('../assets/images/kids-gallery/small-19.jpg') ,
					webp: require('../assets/images/kids-gallery/small-19.webp'),
					size: 'no-grow'
				}			
			]
			} 
		},
		methods: {
			showGalleryImg(index) {
				this.indexGallery = index
				this.visibleImg = true
			},
			handleGalleryHide() {
				this.visibleImg = false
			}
		}
	}
</script>
